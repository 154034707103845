import React from "react";
import { motion } from "framer-motion";

const App = () => {
  return (
    <div
      style={{
        backgroundColor: "black",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: "white",
      }}
    ></div>
  );
};

export default App;
